<template>
  <div class="content">
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-10 offset-md-1">
          <card>
          <h4 slot="header" class="card-title">{{$t('Contacto')}}</h4>
          <form>
            <div class="row">
              <div class="col-md-6">
                <base-input type="text" :label="$t('Nombre')"  v-model="name">
                    <template v-slot:textError v-if="$v.name.$error && !$v.name.required">{{ $t("Campo_requerido") }}</template>
                </base-input>
              </div>
              <div class="col-md-6">
                <base-input type="text" :label="$t('Apellido')"  v-model="lastname">
                  <!-- <template v-slot:textError v-if="$v.lastname.$error && !$v.lastname.required">{{ $t("Campo_requerido") }}</template> -->
                </base-input>
              </div>
            </div>
             <div class="row">
              <div class="col-md-6">
                <base-input type="text" :label="$t('document')"  v-model="document">
                <template v-slot:textError v-if="$v.document.$error && !$v.document.required">{{ $t("Campo_requerido") }}</template>
                </base-input>
              </div>

            </div>
            <div class="row">
              <div class="col-md-6">
                <base-input type="text" :label="$t('Email')"  v-model="email">
                    <template v-slot:textError v-if="$v.email.$error && !$v.email.required">{{ $t("Campo_requerido") }}</template>
                </base-input>
              </div>
              <div class="col-md-6">
                <base-input type="text" :label="$t('phone')"  v-model="phone"></base-input>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <base-input type="text" :label="$t('Direccion')" @clickEvent="onSearchAdrress" :addonRightIcon="'fas fa-search'"  v-model="address">
                  <template v-slot:textError v-if="error_server">{{ error_server }}</template>
                  <template v-slot:textInfo >{{ full_address }}</template>
                </base-input>
              </div>
            </div>
            <div class="row">
              <div class="col-md-4">
                  <base-input type="text" :label="$t('Estado')"  v-model="state"></base-input>
              </div>
              <div class="col-md-4">
                  <base-input type="text" :label="$t('Ciudad')"  v-model="city"></base-input>
              </div>
              <div class="col-md-4">
                  <base-input type="text" :label="$t('zipcode')"  v-model="zipcode"></base-input>
              </div>
            </div>
            </form>
            <div class="row mb-4">
               <div class="col-lg-3 mb-2">
                  <div>
                    <button type="button" @click="OnDeleteContact()" class="btn btn-sm bg-danger text-white">
                      <span class="px-2">
                        <i class="fa fa-trash mr-2"></i>
                        {{$t("Eliminar")}}
                      </span>
                    </button>
                  </div>
               </div>
                <div class="col-lg-9 d-flex justify-content-end">
                  <div>
                      <button class="btn btn-sm bg-dark text-white mr-2" v-if="id" @click="onShowModalUser">
                        <span class="">
                          <i class="fa fa-user mr-2"></i>
                          {{$t("create_user")}}
                        </span>
                    </button>
                     <!-- <button class="btn bg-dark text-white mr-2" v-if="id" @click="showModal = true">
                        <span class="">
                          <i class="fa fa-file-alt mr-2"></i>
                          {{$t("NuevoTramite")}}
                        </span>
                    </button> -->
                    <button type="button" @click="submit()"  class="btn btn-sm bg-success text-white ">
                      <span class="px-2">
                        <i class="fa fa-check mr-2"></i>
                        {{$t("Guardar")}}
                      </span>
                    </button>
                  </div>
                </div>
            </div>
            <!-- <div class="row" v-if="tableData.length > 0">
                <div class="col-12 mb-2 ml-2 ">
                    <h5 class="text-secondary text-uppercase">{{$t("Solicitudes")}}</h5>
                </div>
                <div class="col-12">
                 <l-table class="mb-2"
                            :columns="table.columns"
                            :section="tableSection"
                            :urlDownload="urlDownload"
                            :urlEmail="urlEmail"
                            @send_contract="onSendContract"
                            @upload_contract="onShowModal"
                            :data="tableData">
                    </l-table>
                </div>
            </div> -->
            <div class="row" v-if="tableAttachment.length > 0" >
                <div class="col-12 mt-4 ml-2">
                    <h5 class="text-secondary text-uppercase">{{$t("Adjuntos")}}</h5>
                </div>
                <div class="col-12">
                 <l-table class="mb-2"
                            :columns="table.columnsAttachment"
                            :urlDownload="urlDownloadAttachment"
                            :data="tableAttachment">
                    </l-table>
                </div>
            </div>
            <div class="row" v-if="users.length > 0" >
                <div class="col-12 mt-4 ml-2">
                    <h5 class="text-secondary text-uppercase">{{$t("Usuarios")}}</h5>
                </div>
                <div class="col-12">
                 <l-table class="mb-5"
                            :columns="table.columnsUser"
                            @deleteEvent="onDeleteUser"
                            @editEvent="onEditUser"
                            :data="users">
                    </l-table>
                </div>
            </div>
            <div class="clearfix"></div>
        </card>
        </div>
      </div>
    </div>
    <modal v-if="showModalMaxUser" :showButton="false" :showClose="true" @close="onCloseModalMaxUser" :title="$t('alert_max_users')" :showBtnAccept="false" :showBtnCancel="false" :size="'md'" >
      <div>
        <p>Tiene contratados un máximo de <strong>{{ this.max_users }}</strong> usuarios en su licencia CleverDocs.</p>
        <p>Para crear un nuevo usuario debería contratar más usuarios pinchando <a target="_blank" href="https://www.cleverauditbs.com/contacto">aquí</a> o póngase en contacto con nosotros en:
        </p>
        <div class="row">
          <div id="contact_img" class="col-4">
            <img src="https://legalnet.cleverbsolutions.com/img/logo_bajo.65eecd8e.png" class="logoIndex" width="100%" alt="">
          </div>
          <div class="col-8">
            <p class="colap2">
              <b>CleverBusiness Solutions SL</b><br>
              <a style="font-size:17px;" href="mailto:contacto@cleverbsolutions.com">contacto@cleverbsolutions.com</a><br>
              Travesera de Gracia, 16 entlo. A<br>
              08021 Barcelona<br>
              Tel. <a href="tel:934147283">93 414 72 83</a><br>
            </p>
          </div>
        </div>
      </div>
    </modal>
    <modal v-if="showModalUser" :showClose="true" :btnAcceptText="$t('Guardar')" @close="onCloseModalUser" :title="$t('create_user')" @accept="onCreateUser" :showBtnCancel="false" :size="'xlg'" >
        <div class="row">
          <div class="col-md-12">
              <base-input type="text" :label="$t('Nombre')" v-model="name_user"/>
              <base-input type="text" :label="$t('user_position')" v-model="position"/>
              <base-input type="text" :disabled="!isNew" :label="$t('Email')" v-model="email_contact"/>
              <base-input type="password" v-if="isNew" :label="$t('Password')" v-model="password"/>
            <!--<input  v-if="!isNew" :checked="active" type="checkbox" v-model="active" :value="active" id="chk_active">
            <label for="chk_active">ACTIVADO</label>-->
            <label v-if="!isNew" for="chk_active">{{$t('Activado')}}<base-checkbox id="chk_active" v-if="!isNew" :checked="active" v-model="active"></base-checkbox>
            </label>

              <hr v-if="checkedFolder.length > 0" >
              <div v-if="checkedFolder.length > 0" class="bg-light p-1 d-flex">
                <div class="py-1 px-2 m-1 boder-dashed text-uppercase" v-for="(row, index) in checkedFolder" v-bind:key="index">
                    <span><i class="fa fa-folder folder-icon mr-2 "></i>{{row.name}}</span>
                </div>
              </div>
              <div class="contentRoot" v-if="!isNew">
                <label class="control-label">{{$t("directory")}} </label>
                <ejs-treeview v-if="show" ref='treeview' :autoCheck="false" @nodeChecked="onCheckedNode" :checkedNodes='checkedNodes' :fields="fields" :showCheckBox='true'></ejs-treeview>
              </div>
              <div v-if="errorUser">
                  <span class="invalid-feedback py-2">{{errorUser}}</span>
              </div>
            </div>
        </div>
      </modal>
      <div>
        <vue-easy-lightbox
            escDisabled
            moveDisabled
            :visible="visible"
            :imgs="imgs"
            :index="index"
            @hide="handleHide"
          ></vue-easy-lightbox>
      </div>
  </div>
</template>
<script>
  import { apiCallSingle } from '../../mixins';
  const tableColumns = ['request_type', 'insolvency', 'honoraries', 'created_at' , 'status', 'options', 'download'];
  import { required } from 'vuelidate/lib/validators';
  import Modal from "@/components/Modal.vue";

  export default {
    mixins: [apiCallSingle],
    data () {
      return {
        total_users: 0,
        imgs: '',  // Img Url , string or Array of string
        visible: false,
        index: 0,   // default: 0
        position : null,
        active : null,
        endpoint  : "contacts",
        tableSection : "/admin/mediation",
        section   : "admin/contacts",
        urlDownload : "mediation/download/",
        urlDownloadAttachment : "document/download/",
        urlEmail    : "mediation/send_contract/",
        name      : null,
        lastname  : null,
        phone     : null,
        email     : null,
        message   : null,
        document  : null,
        max_users : null,
        is_primary : null,
        city      : null,
        address   : null,
        zipcode   : null,
        state     : null,
        full_address : null,
        showModal : false,
        showModalUpload : false,
        request_type_id : null,
        servicesTypes : [],
        tableData : [],
        error_server : null,
        files : null,
        password : null,
        showModalUser : false,
        showModalMaxUser: false,
        documents : [],
        errorUser : null,
        show : false,
        users : [],
        checkedNodes : [],
        email_contact : null,
        name_user   : null,
        user_id : null,
        isNew : true,
        folders : [],
        checkedFolder : [],
        fields:
        { 
          ataSource : {},
          id: 'id',
          parentID: 'parent_id',
          text: 'name',
          iconCss: 'icon',
          hasChildren: 'hasChild'
        },
        table:
        {
          columns: [...tableColumns],
          columnsAttachment :
          [
            'name',
            'created_at',
            'url'
          ],
          columnsUser :
          [
            'name',
            'user_position',
            'Email',
            'active',
            'edit_event',
            'delete_event'
          ]
        },
        tableAttachment : [],
        fileTypes : {
            'application/pdf' : 2,
            'image/jpeg'      : 4,
            'image/png'       : 4,
            'image/gif'       : 4,
            'video/mp4'       : 9,
            'audio/mpeg'      : 8,
            'audio/mp3'       : 8,
            'text/plain'      : 10,
            'application/zip' : 5,
            'application/x-zip-compressed' : 5,
            'application/msword' : 3,
            'application/vnd.openxmlformats-officedocument.wordprocessingml.document' : 3,
            'application/vnd.ms-powerpoint' : 6,
            'application/vnd.openxmlformats-officedocument.presentationml.presentation' : 6,
            'application/vnd.ms-excel'  : 7,
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'  : 7,
            'text/csv'  : 7
        },
      }
    },
    props : {
      id : String
    },
    components : {
      Modal
    },
    created ()
    {
      this.onLoadRequestTypes ();
      this.onLoadFolders ();
    },
    validations ()
    {
        return {
          name : {
            required
          },
          document :  {
            required
          },
          email: {
            required
          }
        }
      },
  methods: {
    showImg ()
    {
      this.visible = true
    },
    handleHide ()
    {
      this.visible = false
    },
    getFolderChecked (folder_ids)
    {
      this.checkedFolder = [];
      this.folders.forEach ((items) => {
        if (folder_ids.includes (String (items.id)))
        {
          this.checkedFolder.push (items) 
        }
      });     
    },
    loadData ()
    {
          this.name     = this.data.name;
          this.lastname = this.data.lastname;
          this.phone    = this.data.phone;
          this.email    = this.data.email;
          this.document = this.data.document;
          //this.max_users = this.data.max_users;
          this.is_primary= this.data.is_primary,
          this.city     = this.data.city
          this.address  = this.data.address,
          this.state    = this.data.state;
          this.zipcode  = this.data.zipcode;
          this.data.user_client.forEach ((item) => {
            item.user_position  = item.client_user.position;
          });   
          this.users    = this.data.user_client;
          this.data.mediations.forEach((item) => {
              item.request_type = "Mediación",
              item.honoraries = this.convertToCurrency(item.honoraries);
              item.insolvency = item.insolvency ? item.insolvency.name : '';
              item.status     = item.status ? item.status.name : '';
              item.download   = item.status_id > 1 ? true : false;
            });
          this.tableData = this.data.mediations;
          this.data.documents.forEach((item) => {
            item.created_at = this.$moment(item.created_at).format("DD/MM/YYYY"),
            item.url = true;
          });
          this.tableAttachment = this.data.documents;
      },
      onCloseModalMaxUser()
      {
        this.showModalMaxUser = false;
        this.email_contact = null;
        this.name_user   = null;
        this.position   = null;
        this.active   = null;
        this.user_id = null;
        this.isNew = true;
        this.checkedFolder = [];      },
      onCloseModalUser ()
      {
        this.showModalUser = false;
        this.email_contact = null;
        this.name_user   = null;
        this.position   = null;
        this.active   = null;
        this.user_id = null;
        this.isNew = true;
        this.checkedFolder = [];
      },
      onDeleteUser (item)
      {
        this.$dialog.confirm ({
              title: this.$t("UserContact"),
              body: this.$t("UserContactText"),
              okText: this.$t("Aceptar"),
              cancelText: this.$t("Cancelar")
          })
          .then (() => {
            this.$axios.delete ("/user/" + item.id)
            .then (() => {
              this.fetch ();
            })
          })
      },
      onCheckedNode ()
      {
        let checked_nodes = this.$refs.treeview.ej2Instances.checkedNodes;
        this.getFolderChecked (checked_nodes);
      },
      getProperties ()
      {
          return {
              name    : this.name,
              lastname: this.lastname,
              email   : this.email,
              phone   : this.phone,
              document: this.document,
              //max_users: this.max_users,
              is_primary: this.is_primary,
              city    : this.city,
              address : this.address,
              state   : this.state,
              zipcode : this.zipcode
          }
      },
      convertToCurrency (amount)
      {
          return new Intl.NumberFormat ('de-DE', { style: 'currency', currency: 'EUR' }).format (amount)
      },
      onAccept ()
      {
        this.showModal = false;
        let data = {
          contact_id      : this.id,
          request_type_id : this.request_type_id,
          message :  this.message
        };
        this.$axios.post ("/request_services", data)
            .then ((response) => {
                this.$router.push ('/admin/procesar-solicitud/' + response.data.id);
            })
            .catch (() => {
              this.$dialog.alert ({
                    title: this.$t("NuevoTramite"),
                    body: this.$t("server_error")
                })
            })
      },
      onShowModalUser ()
      {

        this.email_contact = this.email;
        this.name_user = this.lastname ? this.name + " " + this.lastname : this.name;
        this.password = null;
        this.position = null;
        this.active   = null;
        this.isNew = true;
        this.checkedNodes = [];
        this.checkedFolder = [];
        this.$axios ("/contacts")
            .then ((response) => {
              this.data = response.data;
              this.data.forEach ((element) => {
                if(element.is_primary){
                  this.max_users = element.max_users;
                }
              });
            })
            .catch(() => {
              this.$store.dispatch ("isLoadingOff");
            });
        this.$store.dispatch ("isLoadingOn");
        this.$axios ("/user")
          .then ((response) => {
            this.data = response.data;
            this.total_users = this.data.length;
            console.log(this.total_users);
            console.log(this.max_users);
            if (this.total_users < this.max_users){
              this.errorUser = null,
                  this.showModalUser = true;
            }else{
              this.errorUser = null,
                  this.showModalMaxUser = true;
            }
            this.$store.dispatch ("isLoadingOff");
          })
          .catch(() => {
            this.$store.dispatch ("isLoadingOff");
          });
      },
      OnDeleteContact ()
      {
        let self = this;
        if(self.is_primary){
          self.$dialog.alert({
            title: self.$t("primary_contact"),
            body: self.$t("primary_contact_delete_message"),
          })
        }else{
          self.$dialog.confirm({
              title: self.$t("EliminarContacto"),
              body: self.$t("EliminarContactoText"),
              okText: self.$t("Eliminar"),
              cancelText: self.$t("Cancelar"),
            })
            .then(function() {
              if(self.users){
                /*self.$dialog.alert({
                  title: self.$t("primary_contact"),
                  body: self.$t("primary_contact_delete_message"),
                });*/
                Object.keys(self.users).forEach( key => {
                  //let value = JSON.stringify(self.users[key]);
                  let value = self.users[key];
                  Object.entries(value).forEach(([key, value]) => {
                    if (key === 'id'){
                      self.$axios.delete ("/user/" + value)
                          .then (() => {
                            self.fetch ();
                          });
                    }
                  });
                });
              }
                self.$axios.delete("/contacts/"+self.id)
                    .then(() =>{
                      self.$router.push('/admin/contacts');
                    })
                    .catch(() => {
                      self.$dialog.alert({
                        title: self.$t("EliminarContacto"),
                        body: self.$t("server_error")
                      })
                    })

            })
        }
      },
      onLoadRequestTypes ()
      {
        this.$axios("/conf/services_types")
              .then((response) =>{
                  this.servicesTypes = response.data;
              })
      },
      onSearchAdrress ()
      {
        this.$store.dispatch('isLoadingOn');
        this.error_server = null;
        if(this.address.length >= 3){
          this.$axios("geolocation?search="+this.address)
          .then((response) => {
            let data = response.data.data.results;
            if(data.length > 0)
              this.onLoadAdrress(data);
            else
              this.error_server = this.$t("error_location");
            this.$store.dispatch('isLoadingOff');
          })
          .catch(() =>{
            this.$store.dispatch('isLoadingOff');
            this.error_server = this.$t("error_location");
          })
        }

      },
      onSendContract (id)
      {
        this.$store.dispatch('isLoadingOn');
        this.$axios.post( this.urlEmail + id)
          .then(() => {
            this.fetch();
            this.$store.dispatch('isLoadingOff');
            this.$dialog.alert({
                title: this.$t("send_contract"),
                body: this.$t("send_contract_complete")
            })
          })
          .catch(() => {
              this.$store.dispatch('isLoadingOff');
              this.$dialog.alert({
                    title: this.$t("send_contract"),
                    body: this.$t("server_error")
                })
            })
      },
      onLoadAdrress (data)
      {
        let address_components = data[0].address_components
        this.full_address = data[0].formatted_address;
        for(let row in address_components){
          let section = address_components[row];
          switch (section.types[0]) {
            case 'locality':
              this.city = section.long_name;
              break;
            case 'postal_code':
              this.zipcode = section.long_name;
              break;
            case 'administrative_area_level_2':
              this.state = section.long_name;
              break;
          }

       }
      },
      onShowModal (item)
      {
        this.showModalUpload = true;
        this.files = null;
        this.item = item;
      },
      file_upload (event)
      {
        let files = event.target.files;
        let self = this;
        if (files.length > 0) {
            this.error = false;
            let reader = new FileReader();
            reader.readAsDataURL(files[0]);
            reader.onload = () => {
                self.files = {
                    name        : files[0].name,
                    file_type   : self.fileTypes[files[0].type],
                    image_64    : reader.result.split(',')[1]
                };
            };
          }
      },
      onUploadContract ()
      {
        this.$store.dispatch('isLoadingOn');
        this.$axios.post("/mediation/upload_contract/"+this.item.id, this.files)
          .then(() => {
            this.item = null;
            this.showModalUpload = false;
            this.fetch();
            this.$store.dispatch('isLoadingOff');
          })
          .catch(() => {
            this.$store.dispatch('isLoadingOff');
          })
      },
      file_reset ()
      {
        this.files = null
      },
      onEditUser (data)
      {
        
        this.checkedNodes = [];
        data.folders.forEach (item => {
          this.checkedNodes.push (String (item.folder_id));
        });
        this.getFolderChecked (this.checkedNodes);
        this.email_contact = data.email;
        this.name_user = data.name;
        this.active = data.active;
        this.position = data.client_user.position;
        this.user_id = data.id;
        this.isNew = false;
        this.errorUser = null,
        this.showModalUser = true;
        //console.log ("this edit: ", this);
        //console.log ("data edit: ", data);
      },
      onCreateUser ()
      {
        /*if (!this.isNew){
          if (this.checkedFolder.length == 0 && this.checkedNodes.length == 0){
            this.$dialog.alert ({
              title: this.$t("create_user"),
              body: this.$t("El usuario debe tener una carpeta asiganda")
            });
            this.$store.dispatch ('isLoadingOff');
          }
        }*/
        if (this.validationsUser ())
        {
            this.$store.dispatch ('isLoadingOn');
            let nodes = this.$refs.treeview ? this.$refs.treeview.ej2Instances.checkedNodes : this.checkedNodes;
            if (this.isNew)
            {
                let data = {
                      email     : this.email_contact,
                      name      : this.name_user,
                      position  : this.position,
                      active    : this.active,
                      password  : this.password,
                      contact_folders : nodes,
                      contact_id : this.id
                };
                //console.log ("this: ", this);
                //console.log ("data: ", data);
                //data.client_user.position = this.position;
                this.$axios ("/user?where-email=" + this.email_contact)
                .then ((response) => {
                  if (response.data.length > 0)
                  {
                      this.errorUser = this.$t("busy_user");
                      this.$store.dispatch ('isLoadingOff');
                  }
                  else
                  {
                    this.onSaveUser (data);
                  }
                })
            }
            else
            {

              if (nodes.length == 0){
                this.$dialog.alert ({
                  title: this.$t("create_user"),
                  body: this.$t("El usuario debe tener una carpeta asiganda")
                });
                this.$store.dispatch ('isLoadingOff');
              }else{
                let data = {
                  name      : this.name_user,
                  position  : this.position,
                  active    : this.active,
                  contact_folders : nodes,
                  id        : this.user_id
                };
                //console.log ("this2: ", this);
                //console.log ("data2: ", data);
                //data.client_user.position = this.position;
                this.onSaveUser (data);
              }
            }
        }
      },
      onSaveUser (data)
      {
        console.log("onSaveUser");

        let request;
        if (this.isNew) request = this.$axios.post ("/client/user", data)
        else request = this.$axios.put ("/client/user/" + data.id, data)
                  
        request.then (() => {
              this.showModalUser = false;
              this.fetch (); 
              this.$dialog.alert ({
                  title: this.$t("create_user"),
                  body: this.$t("msg_user_created")
              });
              this.$store.dispatch ('isLoadingOff');
          })
          .catch (() => {
            this.$store.dispatch ('isLoadingOff');
          })
      },
      validationsUser ()
      {
        console.log("validationsUser");
        let validation = true;
        this.errorUser = null;
        if(!this.name_user || this.name_user == ""){
          validation = false;
          this.errorUser = this.$t("name_validation");
        }
        else if(!this.email_contact || this.email_contact == ""){
          validation = false;
          this.errorUser = this.$t("email_validation");
        }
        else if(!this.position || this.position == ""){
          validation = false;
          this.errorUser = this.$t("position_validation");
        }
        else if(this.isNew && !this.password){
          validation = false;
          this.errorUser = this.$t("password_validation");
        }
        else if(this.isNew && this.password.length < 8){
          validation = false;
          this.errorUser = this.$t("password_length");
        }     
        return validation;
      },
      onLoadFolders ()
      {
        this.$axios ("documents?where-document_type_id=1")
          .then ((response) => {
              this.folders = response.data;
              this.fields.dataSource = this.getParents (this.folders);
              this.show = true;
          })
      },
      getParents (data)
      {
        let keys = [];
        for (let row in data)
        {
          if (!keys.includes (data [row].parent_id)) keys.push (data [row].parent_id);
        }
        data.forEach ((item) => {
          if (keys.includes (item.id)) item.hasChild = true;
          item.icon = 'fa fa-folder folder-icon';        
        });
        data.unshift ({ id: 0, name: 'INICIO', icon : 'fa fa-folder folder-icon', hasChild: true, expanded: true});
        return data;
      }
    }
  }

</script>
<style>
.modal-xlg{
    max-width: 1000px;
}
.modal-xlg .modal-content{
    min-height: 500px;
}
.table > thead > tr > th{
    color: #535353;
    padding: 15px 10px;
}
.table.table thead{
  /*background: #c7e1f8;*/
  padding: 15px;
}
.folder-icon{
    color: #eac559;
  }
.e-treeview .e-list-item.e-active > .e-text-content .e-list-text{
  color: #141414 !important;
  font-weight: bold;
}
.e-list-text{
  text-transform: uppercase;
}
  .e-checkbox-wrapper:hover .e-frame.e-check, .e-css.e-checkbox-wrapper:hover .e-frame.e-check ,.e-checkbox-wrapper .e-frame.e-check, .e-css.e-checkbox-wrapper .e-frame.e-check{
    background: #5d5d5d;
  }
  .contentRoot{
    max-height: 400px;
    overflow: hidden scroll;
  }
  /* .e-checkbox-wrapper, .e-css.e-checkbox-wrapper {
    pointer-events: none !important;
  } */
</style>
